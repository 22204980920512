<template>
  <v-stepper v-model="step">
    <v-stepper-items>
      <v-stepper-content step="1">
        <v-container>
          <v-row justify="center" class="font-weight-light headline text-center"
            >Insira seu telefone abaixo para continuar com o cadastro</v-row
          >
          <v-row justify="center" style="height: 110px;">
            <v-col sm="12" md="8" lg="6" align-self="center" class="pb-0">
              <v-row align="center">
                <v-text-field
                  v-model="telefone"
                  outlined
                  class="centered-input font-weight-medium title"
                  placeholder="Insira seu telefone"
                  single-line
                  hide-details
                  v-mask="telefoneMask"
                  autocomplete="off"
                ></v-text-field>

                <v-slide-x-reverse-transition>
                  <v-btn
                    @click="confirmarTelefone()"
                    :loading="loading"
                    :class="$vuetify.breakpoint.xs ? 'mt-3' : 'ml-3'"
                    :block="$vuetify.breakpoint.xs"
                    rounded
                    dark
                    color="green"
                    depressed
                    v-show="telefone && telefone.length >= 14"
                  >
                    Confirmar
                    <v-icon class="ml-3">mdi-check</v-icon>
                  </v-btn>
                </v-slide-x-reverse-transition>
                <v-dialog
                  :value="dialogRecaptcha"
                  persistent
                  hide-overlay
                  class="elevation-0"
                  width="320"
                >
                  <v-card id="captchaContainer" class="pa-2"></v-card>
                </v-dialog>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-stepper-content>
      <v-stepper-content step="2">
        <v-container>
          <v-row justify="center" class="font-weight-light headline text-center"
            >Você receberá um código por SMS, confirme-o abaixo</v-row
          >
          <v-row justify="center" style="height: 110px;">
            <v-col sm="12" md="8" lg="6" align-self="center" class="pb-0">
              <v-text-field
                outlined
                class="centered-input font-weight-medium title"
                placeholder="Insira o código"
                single-line
                hide-details
                v-mask="'#############'"
                v-model="codigo"
                autocomplete="off"
              ></v-text-field>
              <v-btn
                text
                small
                absolute
                bottom
                center
                color="error"
                @click="voltarTela()"
                >NÃO RECEBI O CÓDIGO</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
export default {
  data() {
    return {
      step: 1,
      telefone: null,
      codigo: null
    };
  },
  computed: {
    dialogRecaptcha() {
      return this.$store.getters.dialogRecaptcha;
    },
    loading() {
      return this.$store.getters.loading;
    },
    telefoneMask() {
      return this.telefone
        ? this.telefone.length > 15
          ? "(##) # ####-####"
          : "(##) ####-#### #"
        : "(##) ####-#### #";
    },
    telefoneUnmasked() {
      if (this.telefone) {
        return this.unmask(this.telefoneMask, this.telefone);
      } else {
        return "";
      }
    }
  },
  watch: {
    step(val) {
      if (val == 2) {
        this.$emit("confirmado", true);
      } else {
        this.$emit("confirmado", false);
      }
    }
  },
  methods: {
    confirmarTelefone() {
      if (!this.loading) {
        this.$store.dispatch("openDialogRecaptcha");
        setTimeout(() => {
          this.$store
            .dispatch("sendSmsVerification", {
              firebaseContainer: "captchaContainer",
              telefone: this.telefoneUnmasked
            })
            .then(() => {
              this.step = 2;
            });
        }, 200);
      }
    },
    voltarTela() {
      (this.codigo = null), (this.step = 1);
    },
    unmask(mask, value) {
      let tempValue = "";
      mask.split("").forEach((v, index) => {
        if (v === "#" && index < value.length) {
          tempValue += value[index];
        }
      });
      return tempValue;
    }
  }
};
</script>

<style scopped>
.centered-input input {
  text-align: center;
  color: #074d6e !important;
  margin-top: 0 !important;
  max-height: 50px !important;
}
</style>
